<template>
  <div class="messagePage flex-column-center">
    <div class="messageList">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="initList"
      >
        <div
          class="messageItem"
          v-for="(item ,index) in messageList"
          :key="index"
          @click="goPost(item)"
        >
          <div
            class="divider"
            v-if="index>0"
          ></div>
          <div class="userInfoLine flex-row-center">
            <img
              class="userIcon"
              :src="item.user.avatar"
              alt=""
            >
            <div class="messageTitle">我回复了 <span class="userName">{{item.target_user_nickname}}</span></div>
          </div>
          <div class="messageText">{{item.content}}</div>
          <!-- <div class="postText">对呀，这个设计师特别厉害，设计的东西都很漂亮呢对呀，这个设计师特别厉害，设计的东西都很漂亮呢对呀，这个设计师特别厉害，设计的东西都很漂亮呢</div> -->
          <div class="time">{{getTime(item.create_time)}}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { getMessageList } from '../../api/service'
import { getFormateTime } from '../../util/timeUtil'

export default {
  name: 'Message',
  data() {
    return {
      messageList: [],
      cursor: '',
      loading: false,
      finished: false
    }
  },
  mounted() {},
  methods: {
    initList() {
      getMessageList({
        cursor: this.cursor,
        page_size: 20
      }).then((res) => {
        this.cursor = res.data.cursor
        res.data.list.map((item) => {
          item.followStatus = true
          this.messageList.push(item)
        })
        this.loading = false
        if (!res.data.cursor) {
          this.finished = true
        }
      })
    },
    getTime(time) {
      return getFormateTime(parseInt(time))
    },
    goPost(item) {
      this.$router.push({
        path: 'postDetail',
        query: { postId: item.post_id }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Message.scss';
</style>